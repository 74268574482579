import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SignInButton } from '../components/SignInbutton';
import { useApi } from '../hooks/useApi';

export interface LandingPageProps {
}

export const LandingPage = (props: LandingPageProps) => {
    const navigate = useNavigate();
    const { isLoggedIn } = useApi();


    if (isLoggedIn()) {
        navigate("/dashboard");
    }



    return (
      <div>
        <p>Hello world!</p>
            <SignInButton></SignInButton>
        </div>
  );
}
