import { IAuthProvider } from "./IAuthProvider";

export interface DiscordOAuthOptions {
	clientId: string;
	prompt: string;
	redirectUri: string,
	responseType: string;
	scope: string | Array<string>,
	state?: string | null | undefined,
}

export interface OAuthWindowPopupOptions {
	windowName?: string | null | undefined,
	windowOptions?: string | null | undefined,
	path: string,
	callback: Function

}

export const DISCORD_ACCESS_TOKEN_KEY: string = "DISCORD_ACCESS_TOKEN_KEY";

export class DiscordAuthProvider implements IAuthProvider {
	private options: DiscordOAuthOptions;
	private oauthWindow: Window | undefined | null;
	private oauthWindowIntervalHandle: number | undefined;
	private storage: Storage;

	constructor(storage: Storage, options: DiscordOAuthOptions) {
		this.options = options;
		this.storage = storage;
    }

	async getToken() {
		let token = this._acquireTokenFromCache();

		if (token == null || token == undefined) {
			token = this._acquireTokenSilent();
		}

		if (token == null || token == undefined) {
			this._showLoginPopup();
		}
		return new Promise<string>(() => token);

	}
	_acquireTokenFromCache() {
		if (this.storage != null && this.storage != undefined) {
			let token = this.storage.getItem(DISCORD_ACCESS_TOKEN_KEY);
			return this.validateToken(token);
		}
		return null;
	}
	validateToken(token: string| null) {
		return null;
	}
	_acquireTokenSilent() {
		return null;
	}
	_showLoginPopup() {
		if (this.options.state == null || this.options.state == undefined) {
			let state = this._generateState(Math.floor(Math.random() * 100));
			this.options.state = state;
		}
		let targetUrl = this._generateAuthUrl(this.options);
		let opts: OAuthWindowPopupOptions = {
			path: targetUrl,
			callback: (redirectUri: string) => {
				let split = redirectUri.split("?");
				split.shift();
				let params = new URLSearchParams(split[0]);
				let code = params.get("code");
				let state = params.get("state");
				console.log("code=" + code + ", state=" + state);
				//that.handleDiscordResponse(code, state);
			}
		};
		//this._showAuthWindow(opts);
		window.location.href = opts.path;
	}

	_generateAuthUrl(options: DiscordOAuthOptions) {
		const obj = {
			client_id: options.clientId,
			prompt: options.prompt,
			redirect_uri: options.redirectUri,
			response_type: options.responseType || "code",
			scope:
				options.scope instanceof Array
					? options.scope.join(" ")
					: options.scope,
			state: options.state,
		};

		const encoded_string = this._builldQueryString(obj);

		return `https://discord.com/oauth2/authorize?${encoded_string}`;
	}
	_generateState(length: number) {
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() *
				charactersLength));
		}
		return result;
	}


	_builldQueryString(obj: object) {
		let string = "";

		for (const [key, value] of Object.entries(obj)) {
			if (!value) continue;
			string += `&${encodeURIComponent(key)}=${encodeURIComponent(
				value,
			)}`;
		}

		return string.substring(1);
	}

	//This makes the browser funky if you cancel out of the popup window
	_showAuthWindow(options: OAuthWindowPopupOptions) {
		options.windowName = options.windowName || 'ConnectWithOAuth'; // should not include space for IE
		options.windowOptions = options.windowOptions || 'location=0,status=0,width=800,height=800';
		var that = this;
		options.callback = options.callback || function (uri: string) {
			console.log(window.location);
		};
		that.oauthWindow = window.open(options.path, options.windowName, options.windowOptions);
		that.oauthWindowIntervalHandle = window.setInterval(() => {
			if (that.oauthWindow != undefined
				&& that.oauthWindow != null
				&& that.oauthWindow.location != null) {

				if (that.oauthWindow.location.href.startsWith(that.options.redirectUri)) {
					window.clearInterval(that.oauthWindowIntervalHandle);
					options.callback(that.oauthWindow.location.href);
					that.oauthWindow.close();
				}
				if (that.oauthWindow.closed) {
					window.clearInterval(that.oauthWindowIntervalHandle);
				}
			}
			else {
				console.log("Something ain't right, stopping the loop");
				if (that.oauthWindow != null) {
					that.oauthWindow.close();
				}
				window.clearInterval(that.oauthWindowIntervalHandle);
			}
		}, 1000);
	}
}
