import { useEffect, useState } from "react";
import { ApiConfiguration } from "../api/ApiConfiguration";
import { DiscordAuthProvider, DiscordOAuthOptions } from "../api/DiscordAuthProvider";
import { IAuthProvider } from "../api/IAuthProvider";
import { MotoBeBotApi, MOTOBE_BOT_API_TOKEN_KEY } from "../api/MotoBeBotApi";

const baseUrl = window.location.hostname == "localhost" ? "https://localhost:7186/api" : "https://motobe-api.poogramming.com/api";
const redirectUri = window.location.hostname === "localhost" ? "http://localhost:3000/" : "https://motobe.poogramming.com/";

const discordOAuthOptions: DiscordOAuthOptions = {
    clientId: "853329466420232224",
    prompt: "none",
    redirectUri: redirectUri,
    responseType: "code",
    scope: ["identify", "guilds", "email"]
};

const provider: IAuthProvider = new DiscordAuthProvider(localStorage, discordOAuthOptions);

export const useApi = () => {
    const [api, setApi] = useState<MotoBeBotApi>();

    const signIn = async () => {
        //call chain is incorrect. We should let the MotoBeBotAPI do this. It can check if everything is good (active session & token,...)

        //this will either return a token or redirect the user
        let result = await provider.getToken();
        if (result != null && result != undefined) {
        }
    }

    const isLoggedIn = () => {
        return localStorage.getItem(MOTOBE_BOT_API_TOKEN_KEY) != null;
    }

    useEffect(() => {
        const config: ApiConfiguration = new ApiConfiguration(baseUrl);
        const api = new MotoBeBotApi(config, localStorage);
        setApi(api);

    }, []);

    return { api, signIn, isLoggedIn };
}