import axios, { AxiosInstance, AxiosResponse } from "axios";
import { ApiConfiguration } from "./ApiConfiguration";
import { IAuthProvider } from "./IAuthProvider";


export const MOTOBE_BOT_API_TOKEN_KEY = "MOTOBE_BOT_API_TOKEN_KEY";

export class MotoBeBotApi {
    private instance: AxiosInstance;
    private token: string | undefined | null;
    private storage: Storage;

    constructor(config: ApiConfiguration, storage: Storage) {
        this.storage = storage;
        this.instance = axios.create();
        this.instance.defaults.baseURL = config.getBaseUrl();
    }

    async _setAuth() {
        this.instance.defaults.headers.common["Authorization"] = "Bearer " + await this.getToken();
    }

    getToken() {
        return this.token;
    }
    async login(data: string) {
        let result = await this.instance
            .post("auth/login", data, { headers: { 'Content-Type': 'application/json' } });

        if (result == undefined || result.status < 200 || result.status >= 300) {
            throw new ApiException(result);
        }
        let sessionId = result.data;
        this.token = sessionId;
        this.storage.setItem(MOTOBE_BOT_API_TOKEN_KEY, sessionId);
        return true;
    }

    async get(url: string) {
        await this._setAuth();
        let result = await this.instance
            .get(url);
        if (result == undefined || result.status < 200 || result.status >= 300) {
            throw new ApiException(result);
        }
        return result;
    }

    async post(url: string, data: string) {
        await this._setAuth();
        let result = await this.instance
            .post(url, data, { headers: { 'Content-Type': 'application/json' } });

        if (result == undefined || result.status < 200 || result.status >= 300) {
            throw new ApiException(result);
        }
        return result;
    }

    async patch(url: string, data: string) {
        await this._setAuth();
        let result = await this.instance
            .patch(url, data);

        if (result == undefined || result.status < 200 || result.status >= 300) {
            throw new ApiException(result);
        }
        return result;
    }

    async delete(url: string) {
        await this._setAuth();
        let result = await this.instance
            .delete(url);

        if (result == undefined || result.status < 200 || result.status >= 300) {
            throw new ApiException(result);
        }
        return result;
    }

}


export class ApiException {
    private response: AxiosResponse;

    constructor(axiosResponse: AxiosResponse) {
        this.response = axiosResponse;
    }

    getResponse() {
        return this.response;
    }
}
