import { createContext, FC, useContext } from "react";
import { useApi } from "../hooks/useApi";

export const ApiContext = createContext<any>(null);

export const useAuthContext = () => useContext(ApiContext)

interface ApiProviderProps {
    children?: React.ReactNode;
}
export const ApiProvider: FC<ApiProviderProps> = ({ children }) => {
    const auth = useApi();
        
    return <ApiContext.Provider value={auth} >
        {children}
    </ApiContext.Provider>
}