import { useApi } from "../hooks/useApi";

function handleLogin(action: Function) {
    action();
}

export const SignInButton = () => {
    const { signIn } = useApi();

    return (
        <input type="button" onClick={() => handleLogin(signIn)} value="Log in" />
    );
}