import React from 'react';
import { Navigate } from 'react-router-dom';
import { useApi } from '../hooks/useApi';

export interface ProtectedLayoutProps{
}

export const ProtectedLayout = (props: ProtectedLayoutProps) => {
    const { isLoggedIn } = useApi();

    if (!isLoggedIn()) {
        return <Navigate to="/" />;
    }

    //Return user menu
    return (
        <p>Hello Protected world!</p>
    );
}


