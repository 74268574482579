import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { OAuthRedirect } from './components/OAuthRedirect';
import { ProtectedLayout } from './components/ProtectedLayout';
import { ApiProvider } from './Context/ApiProvider';
import './index.css';
import { DashboardPage } from './pages/DashboardPage';
import { LandingPage } from './pages/LandingPage';
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
            <ApiProvider>
                <BrowserRouter>
                <OAuthRedirect />
                    <Routes>
                        <Route path="/dashboard" element={<ProtectedLayout/> } >
                            <Route path="/dashboard/*" element={<DashboardPage/> }></Route>
                        </Route>
                        <Route path="*" element={ <LandingPage /> } / >
                    </Routes>
                </BrowserRouter>
            </ApiProvider>
  </React.StrictMode>
);
reportWebVitals();
