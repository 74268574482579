import React from 'react';

export interface DashboardPageProps {
}

export const DashboardPage = (props: DashboardPageProps) => {
  return (
      <p>Hello world!</p>
  );
}
