import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useApi } from '../hooks/useApi';


class RedirectState {
    code?: string;
    state?: string;

    constructor(code?: string, state?:string) {
        this.code = code;
        this.state = state;
    }
};

export const OAuthRedirect = () => {
    const [redirect, setRedirect] = useState(new RedirectState());
    const [searchParams, setSearchParams] = useSearchParams();
    const { api } = useApi();
    const navigate = useNavigate();

    let result = <></>

    useEffect(() => {
        let redirectUri = window.location.hostname == "localhost" ? "http://localhost:3000/" : "https://" + window.location.hostname + "/"
        let code = searchParams.get("code");
        let state = searchParams.get("state");

        if (code != null && code != undefined && state != null && state != undefined) {

            setRedirect(new RedirectState(code, state));
            const fetchData = async () => {
                let response = await api?.login(JSON.stringify({ code, state, redirectUri }));
                if (response) {
                    navigate("/dashboard");
                }
            }

            fetchData()
                .catch(err => console.log(err));

            
        }
    }, []);

    return result;
}
