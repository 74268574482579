import { IAuthProvider } from "./IAuthProvider";


export class ApiConfiguration {
    private baseUrl: string;
    private authProvider?: IAuthProvider;

    constructor(baseUrl: string, authProvider?: IAuthProvider) {
        this.baseUrl = baseUrl;
        this.authProvider = authProvider;
    }

    getBaseUrl() {
        return this.baseUrl;
    }

    getAuthProvider() {
        return this.authProvider;
    }
}